import React, { useState } from "react";
import i18n from "i18next";
import { icons } from "../../../../assets/icons/icons";

export const LangSelector = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

    const handleLanguageChange = (value: string) => {
        i18n.changeLanguage(value);
        setSelectedLanguage(value);
        setIsDropdownOpen(true);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleBlur = (): void => {
        setTimeout(() => {
            setIsDropdownOpen(false);
        }, 500)
    }

    return (
        <div className="relative flex items-center">
            <div className="relative">
                <button
                    onClick={toggleDropdown}
                    onBlur={handleBlur}
                    className="flex items-center justify-between border-none bg-transparent text-white cursor-pointer font-lato"
                >
                    <img src={icons.header.globe} alt="" className="h-6 w-6 mr-1.5" />
                    {selectedLanguage.toUpperCase()}
                    <svg
                        className={`w-4 h-4 ml-1.5 transition-transform transform ${isDropdownOpen ? "rotate-180" : ""
                            }`}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                        />
                    </svg>
                </button>

                <ul className={`text-center absolute left-1/2 -translate-x-1/2 p-5 bg-black text-white shadow-lg rounded-md z-10${isDropdownOpen ? '' : ' hidden'}`}>
                    <li
                        onClick={() => handleLanguageChange("en")}
                        className={`cursor-pointer px-8 py-2 hover:bg-shark-950`}
                    >EN</li>
                    <li
                        onClick={() => handleLanguageChange("ua")}
                        className={`cursor-pointer px-8 py-2 hover:bg-shark-950`}
                    >UA</li>
                </ul>
            </div>
        </div>
    );
};
