import React from "react";
import {useTranslation} from "react-i18next";

interface CardHeaderProps {
    title: string;
    price: string;
}

export const CardHeader: React.FC<CardHeaderProps> = ({title, price}) => {
    const { t } = useTranslation();
    return (
        <div className='mt-6 md:mt-[50px] flex flex-col items-center sm:items-start font-lato'>
            <span className='leading-none text-[26px] md:text-heading-4 font-bold'>{title}</span>
            <div className='flex flex-row mb-8 mt-4 md:mt-6 xl:mt-12 gap-x-1'>
                <span className='leading-none text-heading-6 font-semibold'>{price}</span>
                <span className='text-body-1 font-normal mt-1.5 text-heading-8 font-montserrat uppercase'>{t("pricing.cards.premium.currency")}</span>
            </div>
        </div>
    );
};
