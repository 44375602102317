import React from "react";
import {useTranslation} from "react-i18next";

export const Header = () => {
    const { t } = useTranslation();

    return (
        <header className="flex flex-col items-center text-white text-center leading-6 mt-[10vh] font-montserrat">
            <div></div>
            <div className="md:h-10 mb-3 leading-normal md:leading-7 text-heading-5 font-bold">
                {t("trusted.header")}
            </div>
            <div className="text-heading-7 font-montserrat px-[40px] max-w-180 text-primary-gray-200">
                {t("trusted.subHeaderFirstLine")} {t("trusted.subHeaderSecondLine")}
            </div>
        </header>
    );
};