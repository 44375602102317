import React, { useRef, useState } from "react";
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

import { Header } from "../header/header";
import { Footer } from "../footer/footer";
import { artColorLogo } from "../../assets/images/common/art-logo-variants/art-big-logos";
import { bgImages } from "../../assets/images/bg-images";
import './contact-us.css'
import { useTranslation } from "react-i18next";
import { Form, Input, Select, Button, Modal } from 'antd';
const { Option } = Select;

interface FormSubmitResult {
    full_name: string,
    email: string,
    company_name: string,
    website: string,
    business_category: string,
    current_role: string,
    company_size: string,
    description: string,
}

interface FormStatusMessages {
    success: string,
    error: string
}

const ContactForm: React.FC = () => {
    const { t } = useTranslation();
    const formRef = useRef();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const sendEmail = async (form: FormSubmitResult, messages: FormStatusMessages) => {
        const data = {
            service_id: 'service_oc9flmi',
            template_id: 'template_kludma6',
            user_id: 'dYIb8V-MtbAEnSGem',
            template_params: form,
        };

        try {
            const response = await fetch('https://api.emailjs.com/api/v1.0/email/send', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                setModalMessage(messages.success);
                setIsModalVisible(true);
            } else {
                const errorData = await response.json();
                console.error(messages.error);
                console.error(errorData);
                setModalMessage(messages.error);
                setIsModalVisible(true);
            }
        } catch (error: unknown) {
            console.error(messages.error);
            console.error(error);
            setModalMessage(messages.error);
            setIsModalVisible(true);
        }
    };

    const inputFields = [
        { title: t('contactUsPage.form.inputFields.f1.title') },
        { title: t('contactUsPage.form.inputFields.f2.title') },
        { title: t('contactUsPage.form.inputFields.f3.title') },
        { title: t('contactUsPage.form.inputFields.f4.title') },
        { title: t('contactUsPage.form.inputFields.f5.title') },
    ];

    const dropdowns = [
        {
            title: t('contactUsPage.form.dropdowns.d1.title'),
            option1: t('contactUsPage.form.dropdowns.d1.option1'),
            option2: t('contactUsPage.form.dropdowns.d1.option2'),
            option3: t('contactUsPage.form.dropdowns.d1.option3'),
            option4: t('contactUsPage.form.dropdowns.d1.option4'),
        },
        {
            title: t('contactUsPage.form.dropdowns.d2.title'),
            option1: t('contactUsPage.form.dropdowns.d2.option1'),
            option2: t('contactUsPage.form.dropdowns.d2.option2'),
            option3: t('contactUsPage.form.dropdowns.d2.option3'),
            option4: t('contactUsPage.form.dropdowns.d2.option4'),
        },
        {
            title: t('contactUsPage.form.dropdowns.d3.title'),
            option1: t('contactUsPage.form.dropdowns.d3.option1'),
            option2: t('contactUsPage.form.dropdowns.d3.option2'),
            option3: t('contactUsPage.form.dropdowns.d3.option3'),
            option4: t('contactUsPage.form.dropdowns.d3.option4'),
        },
    ];

    const onFinish = async (formRef: FormSubmitResult) => {
        console.log('Success:', formRef);
        await sendEmail(formRef, { success: t('contactUsPage.form.mail_success'), error: t('contactUsPage.form.mail_error') })
        const form = document.querySelector<HTMLFormElement>('#contact-form');
        if (form) {
            form.reset();
        }

    };

    const onFinishFailed = (errorInfo: unknown) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Form
                ref={formRef.current}
                name="contact-form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                style={{}}
            >
                <div className="flex gap-x-6 lg:gap-x-15">
                    <Form.Item
                        name="full_name"
                        rules={[{ required: true, message: 'Please input your full name!' }]}
                        style={{ flex: 1, }}
                    >
                        <Input placeholder={inputFields[0].title} style={{ borderColor: '#8a2be2', margin: 'auto' }} />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
                        style={{ flex: 1 }}
                    >
                        <Input placeholder={inputFields[1].title} style={{ borderColor: '#8a2be2' }} />
                    </Form.Item>
                </div>

                <div className="flex gap-x-6 lg:gap-x-15">
                    <Form.Item
                        name="company_name"
                        rules={[{ required: false, message: 'Please input your company name!' }]}
                        style={{ flex: 1 }}
                    >
                        <Input placeholder={inputFields[2].title} style={{ borderColor: '#8a2be2' }} />
                    </Form.Item>

                    <Form.Item
                        name="website"
                        rules={[{ required: false }]}
                        style={{ flex: 1 }}
                    >
                        <Input placeholder={inputFields[3].title} style={{ borderColor: '#8a2be2' }} />
                    </Form.Item>
                </div>

                <Form.Item
                    name="business_category"
                    rules={[{ required: false, message: 'Please select a business category!' }]}
                >
                    <Select placeholder={dropdowns[0].title} style={{ borderColor: '#8a2be2' }}>
                        <Option value="tech">{dropdowns[0].option1}</Option>
                        <Option value="finance">{dropdowns[0].option2}</Option>
                        <Option value="healthcare">{dropdowns[0].option3}</Option>
                        <Option value="education">{dropdowns[0].option4}</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="current_role"
                    rules={[{ required: false, message: 'Please select your current role!' }]}
                >
                    <Select placeholder={dropdowns[1].title} style={{ borderColor: '#8a2be2' }}>
                        <Option value="ceo">{dropdowns[1].option1}</Option>
                        <Option value="cto">{dropdowns[1].option2}</Option>
                        <Option value="developer">{dropdowns[1].option3}</Option>
                        <Option value="designer">{dropdowns[1].option4}</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="company_size"
                    rules={[{ required: false, message: 'Please select your company size!' }]}
                >
                    <Select placeholder={dropdowns[2].title} style={{ borderColor: '#8a2be2' }}>
                        <Option value="small">{dropdowns[2].option1}</Option>
                        <Option value="medium">{dropdowns[2].option2}</Option>
                        <Option value="large">{dropdowns[2].option3}</Option>
                        <Option value="enterprise">{dropdowns[2].option4}</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="description"
                    rules={[{ required: true, message: 'Please input a description!' }]}
                >
                    <Input.TextArea placeholder={inputFields[4].title} rows={1} style={{ borderColor: '#8a2be2' }} />
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                            backgroundColor: '#8a2be2',
                            borderColor: '#8a2be2',
                            width: '100%',
                            color: '#fff',
                        }}
                    >{t('contactUsPage.form.buttonText')}</Button>
                </Form.Item>
            </Form>
            <Modal
                visible={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
                footer={[
                    <Button
                        key="ok"
                        type="primary"
                        htmlType="submit"
                        style={{
                            backgroundColor: '#8a2be2',
                            borderColor: '#8a2be2',
                            width: '100%',
                            color: '#fff',
                        }}
                        onClick={() => setIsModalVisible(false)}
                    >{t('Ok')}</Button>,
                ]}
            >
                <center>
                    <div style={{ marginBottom: '12px' }}>
                        <svg width="38" height="32" viewBox="0 0 38 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M36.22 1.69538L14.1768 21.2243L23.4939 23.6461C23.6665 23.696 23.8512 23.6839 24.016 23.6121C24.1807 23.5403 24.3152 23.4132 24.3963 23.2528L36.3434 1.79565C36.3567 1.77929 36.363 1.75831 36.3608 1.73734C36.3587 1.71636 36.3482 1.69711 36.3319 1.68382C36.3155 1.67052 36.2945 1.66427 36.2736 1.66644C36.2526 1.66861 36.2333 1.67902 36.22 1.69538Z"
                                stroke="white"
                                strokeWidth="1.54257"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M8.28646 18.6799L2.24731 17.1373C2.04276 17.1527 1.84048 17.0861 1.68499 16.9523C1.5295 16.8185 1.43353 16.6285 1.41818 16.4239C1.40284 16.2193 1.46939 16.0171 1.60318 15.8616C1.73698 15.7061 1.92706 15.6101 2.13162 15.5948L36.0527 1.59597C36.0639 1.59086 36.0767 1.59042 36.0883 1.59476C36.0999 1.5991 36.1093 1.60786 36.1144 1.61911C36.1195 1.63036 36.1199 1.64318 36.1156 1.65476C36.1112 1.66633 36.1025 1.6757 36.0912 1.68081L8.28646 18.6491L12.0349 29.8867C12.0666 30.0912 12.1783 30.2748 12.3453 30.397C12.5124 30.5193 12.7212 30.5701 12.9257 30.5384C13.1303 30.5067 13.3139 30.395 13.4361 30.228C13.5583 30.0609 13.6092 29.8521 13.5775 29.6476L14.1868 21.1634"
                                stroke="white"
                                strokeWidth="1.54257"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M13.2568 30.3999L19.1109 22.502"
                                stroke="white"
                                strokeWidth="1.54257"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                    <h2 style={{ marginBottom: '12px' }}>Thank You!</h2>
                    <p style={{ marginBottom: '40px' }}>{modalMessage}</p>
                </center>
            </Modal>
        </>
    );
};

export const ContactUsPage: React.FC = () => {
    const { t } = useTranslation();

    const location = useLocation();

    React.useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname });
    }, [location]);

    return (
        <div className='bg-black text-white w-[100%] overflow-x-hidden font-lato'>
            <Header hideNavigation />
            <main className='w-[100%] grid justify-center md:grid-cols-[1fr] p-4 md:p-10 max-w-[1170px] mx-auto mt-[24px]'>
                <aside
                    className='md:col-start-1 md:row-start-1 z-10 h-fit md:max-w-[50%] lg:max-w-[45%] flex justify-center items-center h-full'
                >
                    <section
                        className='flex flex-col items-center text-center gap-[30px] py-[80px] px-[40px] leading-6 shadow-form-aside _md:mt-30 md:pt-28 md:pb-40 mb-[24px] md:mb-0'
                        style={{
                            backgroundColor: '#1D1C1E',
                            backgroundImage: `url(${bgImages.contactUsPageAside})`,
                            backgroundSize: 'cover',
                        }}
                    >
                        <img
                            src={artColorLogo.color}
                            alt={'Logo'}
                            className='w-[150px] relative'
                        />
                        <div className='text-heading-6'>
                            {t('contactUsPage.aside.header')}
                        </div>
                        <div className="text-shark-400">{t('contactUsPage.aside.subHeader')}</div>
                    </section>
                </aside>
                <article
                    className='p-[16px] md:p-[24px] lg:pr-[70px] w-full mx-auto md:col-start-1 md:row-start-1 md:max-w-[70%] md:pl-[23%] md:mr-0'
                    style={{
                        background:
                            'linear-gradient(136.5deg, #2E2D2E -36.18%, #0B0A0E 83.23%)',
                    }}
                >
                    <h1 className='text-heading-5 pt-[20px] font-bold mx-auto leading-primary mb-6'>
                        {t('contactUsPage.form.header')}
                    </h1>
                    <ContactForm />
                </article>
            </main>
            <Footer />
        </div>
    );
};
