import React from 'react'
import {icons} from '../../../assets/icons/icons'
import {useTranslation} from "react-i18next";

export const CardCustomList = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className='mb-3 mt-8'>{t("pricing.cards.premium.customList.title")}</div>
            <CustomListItem title={t("pricing.cards.premium.customList.item1")}/>
            <CustomListItem title={t("pricing.cards.premium.customList.item2")}/>
            <CustomListItem title={t("pricing.cards.premium.customList.item3")}/>
            <CustomListItem title={t("pricing.cards.premium.customList.item4")}/>
            <CustomListItem title={t("pricing.cards.premium.customList.item5")}/>
            <CustomListItem title={t("pricing.cards.premium.customList.item6")}/>
        </>
    )
}

interface CustomListItem {
    title?: string;
}

export const CustomListItem: React.FC<CustomListItem> = ({title}) => {
    return (
        <div className='flex flex-row items-center mb-3 '>
            <img src={icons.cards.checkmark} alt="check" style={{height: '24px', width: '24px'}}/>
            <div className='flex flex-col ml-4 max-w-[170px] leading-normal'> {title}</div>
        </div>
    )
}


